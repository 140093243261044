import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import { authorPawel as author } from 'data/authors';

import img from 'img/blog/updates/inspect-mode-cover.png';
import video1 from 'video/updates/inspect-mode-feature-news.webm';
import VideoModal from 'components/ui/video-modal';

const Content = () => {
  return (
    <div>
      <p>
        We just introduced a brand new feature called <strong>inspect mode</strong>.
      </p>
      <p className="mt-5">
        When you’re watching a recording, you can switch to the Page Insights tab. The recording
        will stop and you’ll be able to select a particular element. This will allow you to browse
        recordings in which the users have interacted with this element.
      </p>
      <p className="mt-5 mb-5">
        <VideoModal src={{ webm: video1 }} />
      </p>
      <p>You can choose to browse sessions according to two different variables:</p>
      <ul>
        <li>
          <strong>CSS selector</strong> – For instance, if there are different “sign up” buttons on
          the website, you can choose to see sessions where the users have interacted with one
          particular “sign up” button.{' '}
        </li>
        <li>
          <strong>Text</strong> – When you choose to see sessions where someone has interacted with
          the “sign up” text, it will apply to all the “sign up” buttons and hyperlinks.{' '}
        </li>
      </ul>
      <p className="mt-3">
        We hope you like it! Other new improvements are coming soon. If you think something needs
        improvement, or do you have an idea for functionality? Write to us:{' '}
        <a href="mailto:hello@livesession.io">hello@livesession.io</a>
      </p>
    </div>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Inspect mode',
  url: '/blog/inspect-mode/',
  description:
    "Finding sessions with website elements' interactions it's now easier with inspect mode",
  author,
  img,
  date: '2019-11-10',
  category: 'Product Updates',
  group: 'updates',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    group={frontmatter.group}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    descriptionSocial={frontmatter.descriptionSocial || frontmatter.description}
  />
);
